/* App layout styles */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 2rem 0;
}

/* Loading spinner styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(18, 78, 102, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Back button styles */
.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--text-light);
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: auto;
  width: auto;
}

/* Increased specificity to prevent CSS conflicts */
a.back-button, 
.back-button, 
header a.back-button,
.app a.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--text-light);
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: auto;
  width: auto;
}

/* Make sure these styles don't apply to blog headers */
header, 
.blog-header,
.educational-header,
article header {
  position: static;
  background-color: transparent;
  box-shadow: none;
}

.back-button:hover {
  background-color: var(--primary);
  color: var(--text-light);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.back-button:active {
  transform: translateY(0);
}

.back-button::before {
  content: "←";
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .tools-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
  
  .back-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
  
  .back-button::before {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .back-button {
    display: none;
  }
  
  .tool-container {
    padding: 1.5rem;
  }
  
  .tool-container h2 {
    font-size: 1.5rem;
  }
  
  th, td {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

/* Tool card styles for home page and category pages */
.tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.tool-card {
  background-color: var(--text-light);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px var(--shadow-color);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px var(--shadow-color);
}

.tool-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.tool-icon {
  font-size: 1.5rem;
  color: var(--primary);
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--primary-light);
  border-radius: 50%;
  flex-shrink: 0;
}

.tool-card h3 {
  color: var(--primary);
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.tool-description {
  color: var(--text-dark);
  margin-bottom: 1.5rem;
  flex-grow: 1;
  line-height: 1.5;
  opacity: 0.8;
}

.tool-card p {
  color: var(--text-dark);
  margin-bottom: 1.5rem;
  flex-grow: 1;
  line-height: 1.5;
  opacity: 0.8;
}

.card-link {
  display: inline-block;
  background-color: var(--primary);
  color: var(--text-light);
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease;
  text-align: center;
}

.card-link:hover {
  background-color: var(--primary-dark);
}

/* Calculator specific styles */
.calculator-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  overflow-x: hidden;
  width: 100%;
}

@media (min-width: 992px) {
  .calculator-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.input-section {
  background-color: var(--text-light);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px var(--shadow-color);
  height: fit-content;
}

.input-section label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--text-dark);
}

.input-section input,
.input-section select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.input-section input:focus,
.input-section select:focus {
  border-color: var(--primary);
  outline: none;
  box-shadow: 0 0 0 2px rgba(18, 78, 102, 0.1);
}

.result-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  overflow-x: hidden;
}

.result-card {
  background-color: var(--text-light);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px var(--shadow-color);
  width: 100%;
  overflow-x: hidden;
}

.result-card h3 {
  color: var(--primary);
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.result-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
  flex-wrap: wrap;
}

.result-item span:first-child {
  font-weight: 500;
  margin-right: 1rem;
  min-width: 180px;
}

.result-item span:last-child {
  font-weight: 600;
  color: var(--primary-dark);
  text-align: right;
}

.result-item.total {
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid var(--border-color);
  font-weight: 600;
  font-size: 1.05rem;
}

.chart-container {
  background-color: var(--text-light);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px var(--shadow-color);
  height: 350px;
  width: 100%;
  overflow: hidden;
}

.info-section {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  border-left: 4px solid var(--primary-light);
}

.info-section h3 {
  margin-top: 0;
  color: var(--primary);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.info-section p {
  color: var(--text-dark);
  line-height: 1.6;
  margin-bottom: 0.75rem;
}

.info-section p:last-child {
  margin-bottom: 0;
}

.info-section strong {
  color: var(--primary-dark);
  font-weight: 600;
}

.calculate-btn {
  width: 100%;
  margin-top: 1rem;
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 0.85rem 1.5rem;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.calculate-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calculate-btn:active {
  transform: translateY(0);
}

/* FD Calculator responsive fixes */
@media (max-width: 768px) {
  .calculator-grid {
    gap: 1.5rem;
  }
  
  .result-item {
    font-size: 0.9rem;
  }
  
  .result-item span:first-child {
    min-width: 160px;
  }
  
  .chart-container {
    height: 300px;
  }
  
  .info-section {
    padding: 1.25rem;
  }
}

@media (max-width: 576px) {
  .result-item {
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding-bottom: 0.5rem;
  }
  
  .result-item:last-child {
  border-bottom: none;
}

  .result-item span:first-child {
    margin-bottom: 0.25rem;
  }
  
  .result-item span:last-child {
    text-align: left;
  }
  
  .chart-container {
    height: 350px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 400px) {
  .chart-container {
    height: 400px;
  }
  
  .summary-grid {
    grid-template-columns: 1fr;
  }
}

/* Category page styles */
.category-page {
  padding: 2rem 0;
}

.category-page h1 {
  text-align: center;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.category-intro {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 2rem;
  color: var(--text-dark);
  opacity: 0.8;
  line-height: 1.6;
}

/* Common tool container styles */
.tool-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 1.5rem;
  padding: 2rem;
  padding-top: 3.5rem;
  background: var(--text-light);
  border-radius: 12px;
  box-shadow: 0 8px 24px var(--shadow-color);
  border-top: 4px solid var(--primary);
  position: relative;
}

.tool-container h2 {
  color: var(--primary);
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.75rem;
}

/* Result container styles */
.result {
  margin-top: 1.5rem;
  padding: 1rem;
  background: var(--primary-light);
  background: linear-gradient(to right, rgba(18, 78, 102, 0.1), rgba(211, 217, 212, 0.5));
  border-left: 4px solid var(--primary);
  border-radius: 8px;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0.5rem;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-dark);
  font-size: 0.95rem;
}

th {
  font-weight: 600;
  color: var(--primary);
}

/* Hero section styles */
.hero {
  background-color: var(--primary-light);
  padding: 3rem 0;
  text-align: center;
  border-radius: 12px;
  margin-bottom: 2rem;
  color: var(--text-light);
}

.hero h1 {
  color: var(--text-light);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  color: var(--text-light);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

/* Category section styles */
.category-header h2 {
  color: var(--primary);
}

.view-all {
  color: var(--primary);
  font-weight: 600;
}

.view-all:hover {
  color: var(--primary-dark);
}

/* Income Tax Calculator specific styles */
.calculator {
  max-width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: var(--shadow-lg);
  overflow-x: hidden;
}

.calculator h1 {
  text-align: center;
  color: var(--text-dark);
  font-size: 2.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.subtitle-note {
  color: var(--secondary);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.95rem;
}

.regime-comparison {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
}

.regime-card {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 100%;
}

.regime-card:hover {
  transform: translateY(-2px);
}

.regime-card h3 {
  color: var(--text-dark);
  text-align: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--border-color);
}

.regime-card.old-regime {
  border-top: 4px solid var(--secondary);
}

.regime-card.new-regime {
  border-top: 4px solid var(--primary);
}

.breakdown-section {
  background: var(--background);
  margin-bottom: 1.5rem;
  padding: 1.2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.breakdown-section h4 {
  color: var(--text-dark);
  margin: 0 0 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.breakdown-item {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;
  border-bottom: 1px solid var(--border-color);
  font-size: 0.95rem;
}

.breakdown-item:last-child {
  border-bottom: none;
}

.breakdown-item.total {
  font-weight: 600;
  color: var(--text-dark);
  background: rgba(18, 78, 102, 0.05);
  margin: 0.5rem -1.2rem -1.2rem;
  padding: 1rem 1.2rem;
  border-radius: 0 0 8px 8px;
}

.better-option {
  background: linear-gradient(135deg, var(--primary), var(--primary-light));
  color: white;
  padding: 1.2rem;
  margin-top: 2rem;
  border-radius: 12px;
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .regime-comparison {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .calculator {
    padding: 1.5rem;
    margin: 1rem;
  }
  
  .calculator h1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .calculator {
    padding: 1rem;
    margin: 0.5rem;
  }
  
  .regime-card {
    padding: 1rem;
  }
  
  .breakdown-section {
    padding: 1rem;
  }
  
  .breakdown-item {
    font-size: 0.9rem;
  }
}

/* 404 Not Found Page Styles */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4rem 1rem;
  margin: 2rem auto;
  max-width: 700px;
  background-color: var(--text-light);
  border-radius: 12px;
  box-shadow: var(--shadow-lg);
  border-top: 4px solid var(--primary);
}

.not-found-icon {
  font-size: 5rem;
  color: var(--primary);
  margin-bottom: 1.5rem;
  animation: float 3s ease-in-out infinite;
}

.not-found-container h1 {
  color: var(--primary);
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.not-found-container p {
  color: var(--text-dark);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.not-found-subtitle {
  color: var(--secondary) !important;
  font-size: 0.95rem !important;
  opacity: 0.8;
}

.home-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--primary);
  color: var(--text-light);
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  margin-top: 1rem;
  transition: all 0.3s ease;
}

.home-button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Base64 Tool Styles */
.tool-page {
  padding: 2rem 0;
}

.mode-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.mode-button {
  padding: 0.75rem 2rem;
  background-color: var(--text-light);
  border: 2px solid var(--primary);
  border-radius: 8px;
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.mode-button.active {
  background-color: var(--primary);
  color: var(--text-light);
}

.mode-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.conversion-options {
  margin-bottom: 2rem;
}

.option-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.option-group label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--text-dark);
}

.select-input {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--text-light);
  font-size: 1rem;
  color: var(--text-dark);
}

.conversion-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 992px) {
  .conversion-container {
    grid-template-columns: 1fr auto 1fr;
  }
}

.input-section, .output-section {
  background-color: var(--text-light);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px var(--shadow-color);
}

.input-section h3, .output-section h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--primary);
  font-size: 1.25rem;
  font-weight: 600;
}

.input-textarea, .output-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
  resize: vertical;
}

.output-textarea {
  background-color: #f5f5f5;
}

.file-input-container {
  margin: 15px 0;
  position: relative;
  width: 100%;
}

.file-input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.file-input-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-input-info:hover {
  border-color: #007bff;
  background-color: #f0f7ff;
}

.file-placeholder {
  color: #777;
  font-style: italic;
}

.file-name {
  font-weight: 500;
  color: #333;
  word-break: break-all;
  max-width: 70%;
}

.file-browse-btn {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-browse-btn:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.conversion-controls {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
}

.convert-button, .clear-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}

.convert-button {
  background-color: #28a745;
  color: white;
}

.convert-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.convert-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.clear-button {
  background-color: #dc3545;
  color: white;
}

.clear-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.output-placeholder {
  padding: 20px;
  text-align: center;
  color: #777;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.output-preview {
  width: 100%;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.output-image, .output-audio, .output-video, .output-pdf {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  background-color: #f5f5f5;
}

.output-pdf {
  height: 500px;
  border: none;
}

.output-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.action-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 8px 15px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #e0e0e0;
}

.error-message {
  padding: 15px;
  background-color: #fff0f0;
  color: #d32f2f;
  border-left: 4px solid #d32f2f;
  margin: 10px 0;
  border-radius: 0 4px 4px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .conversion-container {
    flex-direction: column;
  }
  
  .input-section, .output-section {
    width: 100%;
  }
  
  .conversion-controls {
    margin: 15px 0;
  }
}

.output-note {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
  text-align: center;
}

.text-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.sample-button {
  align-self: flex-end;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sample-button:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.input-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.paste-button {
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
}

.paste-button:hover {
  background-color: #138496;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fix-button {
  background-color: #ffc107;
  color: #212529;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
}

.fix-button:hover {
  background-color: #e0a800;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fix-button:disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.pdf-preview-message {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.pdf-preview-message p {
  margin: 5px 0;
  color: #495057;
}

.pdf-actions {
  justify-content: center;
  margin-top: 15px;
}

.download-pdf-button {
  background-color: #28a745;
  color: white;
}

.download-pdf-button:hover {
  background-color: #218838;
}

.view-pdf-button {
  background-color: #007bff;
  color: white;
}

.view-pdf-button:hover {
  background-color: #0069d9;
}

.pdf-iframe-container {
  margin-top: 20px;
}

.pdf-iframe-label {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 10px;
  font-style: italic;
}

.output-message {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.output-message p {
  margin: 5px 0 15px;
  color: #495057;
}

/* Base64 Tool Styles */
.note {
  display: inline-block;
  color: #e74c3c;
  font-style: italic;
  margin-left: 5px;
}

/* Time Teller Styles */
.time-teller-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.current-time-display {
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  color: white;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.current-time-display h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: white;
  font-size: 1.8rem;
}

.time-display {
  display: flex;
  justify-content: center;
}

.digital-clock {
  font-family: 'Roboto Mono', monospace, sans-serif;
  text-align: center;
}

.time-value {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 0.5rem;
}

.date-value {
  font-size: 1.2rem;
  opacity: 0.9;
}

.time-formats-container h3,
.timezone-section h3 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary);
  margin-bottom: 1rem;
  font-size: 1.4rem;
  border-bottom: 2px solid var(--primary-light);
  padding-bottom: 0.5rem;
}

.time-formats-card {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.format-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.format-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.format-label {
  font-weight: 600;
  color: var(--text-dark);
  font-size: 0.95rem;
}

.format-value {
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-dark);
  border-left: 3px solid var(--primary);
}

.monospace {
  font-family: 'Roboto Mono', monospace, sans-serif;
  letter-spacing: 0.5px;
}

.timezone-selector {
  margin-bottom: 1.5rem;
}

.timezone-selector label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--text-dark);
}

.timezone-select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-dark);
  background-color: white;
}

.selected-timezone-card {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;
}

.timezone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--border-color);
}

.timezone-header h4 {
  margin: 0;
  color: var(--primary);
  font-size: 1.2rem;
}

.timezone-offset {
  background-color: var(--primary-light);
  color: var(--primary);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
}

.timezone-time {
  font-size: 1.2rem;
  color: var(--text-dark);
}

.timezone-date-time {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.timezone-icon {
  color: var(--primary);
  font-size: 1.4rem;
}

.world-clock-grid h3 {
  margin-bottom: 1rem;
}

.world-clock-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.world-clock-item {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
}

.world-clock-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.world-clock-name {
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.world-clock-time {
  font-size: 1.1rem;
  color: var(--text-dark);
}

@media (max-width: 768px) {
  .format-grid {
    grid-template-columns: 1fr;
  }
  
  .world-clock-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .time-value {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .current-time-display {
    padding: 1.5rem;
  }
  
  .time-value {
    font-size: 2rem;
  }
  
  .date-value {
    font-size: 1rem;
  }
  
  .timezone-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .timezone-offset {
    align-self: flex-start;
  }
}

/* FD Calculator additional styles */
.summary-card {
  background: linear-gradient(135deg, var(--primary-light), var(--text-light));
  border-left: 4px solid var(--primary);
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.summary-item {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}

.summary-label {
  font-size: 0.85rem;
  color: var(--text-dark);
  opacity: 0.8;
  margin-bottom: 0.25rem;
}

.summary-value {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-dark);
}

.summary-value.highlight {
  color: var(--primary);
  font-size: 1.3rem;
}

.input-section h3 {
  color: var(--primary);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.calculate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@media (max-width: 576px) {
  .summary-grid {
    grid-template-columns: 1fr 1fr;
  }
  
  .summary-item {
    padding: 0.5rem;
  }
  
  .summary-value {
    font-size: 1.1rem;
  }
  
  .summary-value.highlight {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .summary-grid {
    grid-template-columns: 1fr;
  }
}

/* Home Loan EMI Calculator Styles */
.tabs-container {
  margin-top: 1.5rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px var(--shadow-color);
  overflow: hidden;
}

.tabs {
  display: flex;
  background-color: var(--primary-light);
  border-bottom: 1px solid var(--border-color);
}

.tab {
  flex: 1;
  padding: 1rem 0.5rem;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-dark);
  transition: all 0.3s ease;
  outline: none;
}

.tab:hover {
  background-color: rgba(18, 78, 102, 0.1);
}

.tab.active {
  background-color: white;
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
  font-weight: 600;
}

.tab-content {
  padding: 1.5rem;
  max-height: 800px;
  overflow-y: auto;
}

.charts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.chart-wrapper {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.chart-wrapper h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--primary);
  font-size: 1.1rem;
}

.pie-chart, .line-chart {
  height: 250px;
  position: relative;
}

.result-details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.result-card h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--primary);
  font-size: 1.1rem;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.amortization-table, .yearly-analysis-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.amortization-table th, .yearly-analysis-table th {
  background-color: var(--primary-light);
  color: var(--primary);
  font-weight: 600;
  text-align: left;
  padding: 0.75rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

.amortization-table td, .yearly-analysis-table td {
  padding: 0.6rem 0.75rem;
  border-bottom: 1px solid var(--border-color);
}

.amortization-table tr:nth-child(even), .yearly-analysis-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.amortization-table tr:hover, .yearly-analysis-table tr:hover {
  background-color: rgba(18, 78, 102, 0.05);
}

.year-end {
  background-color: rgba(18, 78, 102, 0.08) !important;
  font-weight: 500;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  margin-bottom: 1.5rem;
}

.status-good {
  color: #28a745;
  font-weight: 600;
}

.status-warning {
  color: #ffc107;
  font-weight: 600;
}

.affordability-note {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #6c757d;
  font-style: italic;
}

.advanced-toggle {
  margin: 1rem 0;
  cursor: pointer;
  color: var(--primary);
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s ease;
}

.advanced-toggle:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

.advanced-options {
  background-color: rgba(18, 78, 102, 0.05);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  border-left: 3px solid var(--primary-light);
}

.optional {
  font-size: 0.85rem;
  color: #6c757d;
  font-weight: normal;
  font-style: italic;
}

.interest-analysis {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.interest-analysis h4 {
  margin-top: 0;
  color: var(--primary);
  margin-bottom: 1rem;
}

.analysis-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.highlight-box {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  border-left: 3px solid var(--primary);
}

.highlight-box h5 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  color: var(--primary);
  font-size: 1rem;
}

.highlight-box p {
  margin: 0.25rem 0;
  font-size: 0.9rem;
}

@media (max-width: 992px) {
  .charts-container {
    grid-template-columns: 1fr;
  }
  
  .result-details {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .tabs {
    flex-direction: column;
  }
  
  .tab {
    padding: 0.75rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .tab.active {
    border-bottom: 1px solid var(--border-color);
    border-left: 3px solid var(--primary);
  }
  
  .charts-container {
    grid-template-columns: 1fr;
  }
  
  .analysis-highlights {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .amortization-table, .yearly-analysis-table {
    font-size: 0.8rem;
  }
  
  .amortization-table th, .yearly-analysis-table th,
  .amortization-table td, .yearly-analysis-table td {
    padding: 0.5rem;
  }
  
  .tab-content {
    padding: 1rem;
  }
  
  .pie-chart, .line-chart {
    height: 200px;
  }
} 