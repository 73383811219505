.header {
  background-color: var(--primary-dark);
  box-shadow: 0 2px 10px var(--shadow-color);
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.logo h1 {
  color: var(--text-light);
  font-size: 1.5rem;
  margin: 0;
  white-space: nowrap;
}

.main-nav {
  height: 100%;
  display: flex;
  align-items: center;
}

.main-nav ul {
  display: flex;
  list-style: none;
  gap: 0;
  margin: 0;
  padding: 0;
  height: 100%;
}

.main-nav li {
  height: 100%;
  display: flex;
  align-items: center;
}

.main-nav a {
  color: var(--secondary);
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 0 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
}

.main-nav a:hover {
  color: var(--text-light);
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom: 3px solid var(--secondary);
}

.mobile-menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}

.mobile-menu-toggle span {
  width: 100%;
  height: 3px;
  background-color: var(--text-light);
  border-radius: 3px;
  transition: all 0.3s ease;
}

/* Active state for mobile menu toggle */
.mobile-menu-toggle.active span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.mobile-menu-toggle.active span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-toggle.active span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

@media (max-width: 768px) {
  .header-content {
    height: 60px;
  }
  
  .mobile-menu-toggle {
    display: flex;
    z-index: 1001;
  }
  
  .menu-overlay {
    display: block;
  }
  
  .main-nav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    max-width: 300px;
    height: 100vh;
    background-color: var(--primary-dark);
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 70px;
    z-index: 1000;
  }
  
  .main-nav.active {
    right: 0;
  }
  
  .main-nav ul {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  
  .main-nav li {
    width: 100%;
    height: auto;
  }
  
  .main-nav a {
    width: 100%;
    padding: 1.2rem 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 3px solid transparent;
    justify-content: flex-start;
  }
  
  .main-nav a:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 3px solid var(--secondary);
  }
}

@media (max-width: 480px) {
  .logo h1 {
    font-size: 1.2rem;
  }
  
  .main-nav {
    width: 85%;
  }
} 