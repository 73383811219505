@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply antialiased;
  }
  
  body {
    @apply bg-background text-text-dark font-sans min-h-screen m-0 p-0;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-display font-semibold text-text-dark mb-4;
  }

  h1 { @apply text-4xl md:text-5xl tracking-tight; }
  h2 { @apply text-3xl md:text-4xl tracking-tight; }
  h3 { @apply text-2xl md:text-3xl tracking-tight; }
  h4 { @apply text-xl md:text-2xl tracking-tight; }
  h5 { @apply text-lg md:text-xl tracking-tight; }
  h6 { @apply text-base md:text-lg tracking-tight; }

  p {
    @apply mb-4 leading-relaxed;
  }

  a {
    @apply text-primary hover:text-primary-dark transition-colors duration-200 no-underline;
  }
}

@layer components {
  .container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }

  .calculator-container {
    @apply min-h-screen bg-background py-12 px-4 sm:px-6 lg:px-8;
  }

  .calculator-header {
    @apply text-center mb-10;
  }

  .calculator-title {
    @apply text-3xl md:text-4xl font-display font-bold text-primary mb-3;
  }

  .calculator-description {
    @apply text-lg text-gray-600 max-w-3xl mx-auto;
  }

  .calculator-content {
    @apply max-w-5xl mx-auto bg-white rounded-2xl shadow-card p-8 mb-10;
  }

  .calculator-form {
    @apply grid md:grid-cols-2 gap-8 mb-8;
  }

  .calculator-results {
    @apply bg-primary/5 rounded-xl p-6 border border-primary/10;
  }

  .results-title {
    @apply text-xl font-semibold text-primary mb-4;
  }

  .result-card {
    @apply bg-white rounded-xl shadow-sm p-6 border border-primary/10 mb-4;
  }

  .info-card {
    @apply bg-accent/10 rounded-xl p-6 border border-accent/20 mt-8;
  }

  .btn {
    @apply inline-flex items-center justify-center px-6 py-3 rounded-xl font-semibold transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer;
  }

  .btn-primary {
    @apply btn bg-primary text-white hover:bg-primary-dark focus:ring-primary;
  }

  .btn-secondary {
    @apply btn bg-secondary text-white hover:bg-secondary-dark focus:ring-secondary;
  }

  .input-group {
    @apply space-y-2 mb-6;
  }

  .input-label {
    @apply block text-sm font-medium text-gray-700 mb-2;
  }

  .input-field {
    @apply block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-4 py-3;
  }

  .select-field {
    @apply block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-4 py-3;
  }

  .card {
    @apply bg-white rounded-2xl shadow-card p-6;
  }

  .tool-card {
    @apply card hover:shadow-lg transition-shadow duration-300;
  }

  .statistics-grid {
    @apply grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6;
  }

  .statistic-card {
    @apply bg-white rounded-xl p-4 shadow-sm border border-gray-100;
  }

  .statistic-value {
    @apply text-2xl font-bold text-primary;
  }

  .statistic-label {
    @apply text-sm text-gray-600;
  }

  .explanation-section {
    @apply mt-12 bg-white rounded-xl p-6 shadow-sm;
  }

  .explanation-title {
    @apply text-xl font-semibold text-primary mb-4;
  }

  .explanation-text {
    @apply text-gray-600 leading-relaxed;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  
  .health-value-normal {
    @apply text-green-600 font-semibold;
  }
  
  .health-value-warning {
    @apply text-yellow-600 font-semibold;
  }
  
  .health-value-alert {
    @apply text-red-600 font-semibold;
  }
} 