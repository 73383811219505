.footer {
  background-color: var(--primary-dark);
  color: var(--text-light);
  padding: 3rem 0 1.5rem;
  margin-top: 3rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.footer-logo {
  flex: 1;
}

.footer-logo h3 {
  color: var(--secondary);
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.footer-logo p {
  color: var(--background);
  font-size: 0.9rem;
}

.footer-links {
  flex: 2;
  display: flex;
  justify-content: space-around;
}

.footer-section h4 {
  color: var(--secondary);
  margin-top: 0;
  margin-bottom: 1rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: var(--background);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: var(--secondary);
}

.footer-bottom {
  border-top: 1px solid var(--primary);
  padding-top: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  color: var(--secondary);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-logo {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .footer-links {
    flex-direction: row;
  }
}

@media (max-width: 480px) {
  .footer-links {
    flex-direction: column;
    gap: 2rem;
  }
  
  .footer-section {
    text-align: center;
  }
} 